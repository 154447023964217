import React, { useEffect, useState } from "react";
import { Table, Button, Spinner, Alert } from "@themesberg/react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import Axios from "../../axios";

const InvoiceTable = () => {
  const { id } = useParams(); // Get userId from the URL parameters
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await Axios.get(`/api/v1/users/${id}/invoices`); // Replace with your API endpoint

        setInvoices(response.data.invoices);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [id]);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",

    timeZone: "Asia/Kolkata",
  };

  function viewInvoice(invoiceId) {
    // Implement the action for the "View" button, e.g., navigate to a detailed view page
    history.push(`/invoice/${invoiceId}`);
  }

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" />
        <p>Loading invoices...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center my-5">
        <Alert variant="danger">Error: {error}</Alert>
      </div>
    );
  }
  if (invoices && invoices.length == 0) {
    return <h6>NO Invoices found for this user</h6>;
  }
  return (
    <div className="container mt-5">
      <h3 className="mb-4">Invoices of {invoices[0].order?.userId?.name}</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Amount</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Plan Name</th>
            <th>Plan Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr key={invoice._id}>
              <td>{index + 1}</td>
              <td>{invoice.amount}</td>
              <td>
                {new Date(invoice.order?.startDate).toLocaleDateString(
                  "en-US",
                  options
                )}
              </td>

              <td>
                {new Date(invoice.order?.expiryDate).toLocaleDateString(
                  "en-US",
                  options
                )}
              </td>
              <td>{invoice.order?.plan?.planName}</td>
              <td>{invoice.order?.planType}</td>
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => viewInvoice(invoice._id)}
                >
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default InvoiceTable;
