export const Routes = {
  // pages
  Presentation: { path: "/" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/transactions" },
  user: { path: "/components/user" },
  // Settings: { path: "/settings" },

  // BootstrapTables: { path: "/tables/bootstrap-tables" },
  // Billing: { path: "/examples/billing" },
  // Invoice: { path: "/examples/invoice" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },

  NotFound: { path: "/404" },
  ServerError: { path: "/500" },
  Messages: { path: "/messages" },
  ChangePassword: { path: "/ChangePassword" },
  MyProfile: { path: "/MyProfile" },

  // docs
  //   DocsOverview: { path: "/documentation/overview" },
  //   DocsDownload: { path: "/documentation/download" },
  //   DocsQuickStart: { path: "/documentation/quick-start" },
  //   DocsLicense: { path: "/documentation/license" },
  //   DocsFolderStructure: { path: "/documentation/folder-structure" },
  //   DocsBuild: { path: "/documentation/build-tools" },
  //   DocsChangelog: { path: "/documentation/changelog" },

  // components
  //   Accordions: { path: "/components/accordions" },
  //   Alerts: { path: "/components/alerts" },
  //   Badges: { path: "/components/badges" },
  // Widgets: { path: "/widgets" },
  // Breadcrumbs: { path: "/components/breadcrumbs" },
  // Buttons: { path: "/components/buttons" },
  // Forms: { path: "/components/forms" },
  // Modals: { path: "/components/modals" },
  // Navs: { path: "/components/navs" },
  // Navbars: { path: "/components/navbars" },
  // Pagination: { path: "/components/pagination" },
  // Popovers: { path: "/components/popovers" },
  // Progress: { path: "/components/progress" },
  // Tables: { path: "/components/tables" },
  // Tabs: { path: "/components/tabs" },
  // Tooltips: { path: "/components/tooltips" },
  // Toasts: { path: "/components/toasts" },
  // WidgetsComponent: { path: "/components/widgets" },
};
