import React, { useEffect, useState } from "react";
import {
  Table,
  Spinner,
  Alert,
  Button,
  Pagination,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import Axios from "../axios";

const OrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const history = useHistory();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await Axios.get("/api/v1/orders/getAllOrders"); // Replace with your backend API endpoint
        console.log(response.data);
        if (response.data.status === "success") {
          setOrders(response.data.orders);
        } else {
          throw new Error("Failed to fetch orders.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",

    timeZone: "Asia/Kolkata",
  };

  const totalPages = Math.ceil(orders.length / ordersPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  if (loading) {
    return (
      <div className="text-center my-4">
        <Spinner animation="border" variant="primary" />
        <p>Loading orders...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="my-4">
        <Alert variant="danger">Error: {error}</Alert>
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <h4 className="mt-4">Orders</h4>
      <Table hover bordered className="mb-4">
        <thead className="thead-dark">
          <tr>
            <th>S.No</th>
            <th>User Name</th>
            <th>Amount</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Plan Name</th>
            <th>Plan Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.map((order, index) => (
            <tr key={order._id}>
              <td>{indexOfFirstOrder + index + 1}</td>
              <td>{order.userId?.name || "N/A"}</td>
              <td>
                {order.planType === "monthly"
                  ? `${order.plan.monthlyPrice}`
                  : `${order.plan.yearlyPrice}`}
              </td>
              <td>
                {new Date(order.startDate).toLocaleDateString("en-US", options)}
              </td>
              <td>
                {new Date(order.expiryDate).toLocaleDateString(
                  "en-US",
                  options
                )}
              </td>
              <td>{order.plan.planName}</td>
              <td className="text-capitalize">{order.planType}</td>
              <td>
                <button
                  className="btn"
                  style={{ background: "black", color: "white" }}
                  onClick={() => history.push(`/invoice/${order?.invoice}`)}
                >
                  Invoice
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <Pagination className="justify-content-center">
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages)].map((_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default OrdersTable;
