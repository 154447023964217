import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
} from "@themesberg/react-bootstrap";

import Swal from "sweetalert2";
import Axios from "../../axios";
import { useHistory } from "react-router-dom";

const AddPlan = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    planName: "",
    monthlyPrice: "",
    yearlyPrice: "",

    maxkeywords: "",

    text1: "",
    text2: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // const jwt = localStorage.getItem("jwt");
  // console.log("jwt", jwt);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await Axios.post(
        "/api/v1/plans",
        {
          ...formData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        Swal.fire({
          title: "Success!",
          text: "Plan created successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        history.push("/plan");
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error creating the plan.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Container>
      <Card style={{ padding: "1rem", margin: "1rem" }}>
        <Row className="justify-content-md-center">
          <Col md="6">
            <h1 className="text-center mb-4">Create Plan</h1>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group controlId="formPlanName" className="mt-3">
                <Form.Label>Plan Name</Form.Label>
                <Form.Control
                  type="text"
                  name="planName"
                  value={formData.planName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPrice" className="mt-3">
                <Form.Label>Monthly Price</Form.Label>
                <Form.Control
                  type="number"
                  name="monthlyPrice"
                  value={formData.monthlyPrice}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPrice" className="mt-3">
                <Form.Label>Yearly Price </Form.Label>
                <Form.Control
                  type="number"
                  name="yearlyPrice"
                  value={formData.yearlyPrice}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formKeywords" className="mt-3">
                <Form.Label>Max Keywords</Form.Label>
                <Form.Control
                  type="number"
                  name="maxkeywords"
                  value={formData.maxkeywords}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              {/* <Form.Group controlId="formTrialPeriod" className="mt-3">
                <Form.Label>Trial Period (days)</Form.Label>
                <Form.Control
                  type="number"
                  name="trialPeriod"
                  value={formData.trialPeriod}
                  onChange={handleChange}
                  required
                />
              </Form.Group> */}
              <Form.Group controlId="formTest" className="mt-3">
                <Form.Label>Text 1</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text1"
                  name="text1"
                  value={formData.text1}
                  onChange={handleChange}
                  maxLength="100"
                />
                {formData.text1.length > 0 && (
                  <Form.Text className="text-muted">
                    {100 - formData.text1.length} characters left
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="formText" className="mt-3">
                <Form.Label>Text 2</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text2"
                  name="text2"
                  value={formData.text2}
                  onChange={handleChange}
                  maxLength="100"
                  // required
                />
                {formData.text2.length > 0 && (
                  <Form.Text className="text-muted">
                    {100 - formData.text2.length} characters left
                  </Form.Text>
                )}
              </Form.Group>

              <Button variant="info" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default AddPlan;
