import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Row,
  Table,
  ListGroup,
  Badge,
  Button,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
//import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { pageVisits } from "../../data/tables";

import { useParams } from "react-router-dom";
import Axios from "./../../axios";
const ViewUser = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);

  const referralData = [
    { name: "Alice Brown", date: "2024-02-01" },
    { name: "Bob Wilson", date: "2024-02-15" },
    { name: "Carol Davis", date: "2024-03-01" },
    { name: "David Miller", date: "2024-03-10" },
  ];

  const userData = {
    userName: "John Smith",
    referralCode: "JOHN123",
    planName: "Premium",
    planType: "Annual",
    keywords: [
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
      "marketing",
      "social media",
      "content",
    ],
    mobileNumber: "+1-234-567-8900",
    joiningDate: "2024-01-15",
    // Optional fields
    ipAddress: "192.168.1.1",
    os: "Windows 11",
    deviceName: "Desktop-PC",
    deviceResolution: "1920x1080",
    location: "New York, USA",
    deviceId: "DEV-123456",
  };

  const renderField = (label, value) => (
    <ListGroup.Item>
      <Row className="align-items-start">
        <Col xs={4} className="text-start pe-2">
          <strong className="text-muted">{label}:</strong>
        </Col>
        <Col xs={8} className="ps-3 border-start">
          {value}
        </Col>
      </Row>
    </ListGroup.Item>
  );

  //   const TableRow = (props) => {
  //     const { pageName, views, returnValue, bounceRate } = props;
  //     const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
  //     const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

  //     return (
  //       <tr>
  //         <th scope="row">{pageName}</th>
  //         <td>{views}</td>
  //         <td>${returnValue}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={bounceIcon}
  //             className={`${bounceTxtColor} me-3`}
  //           />
  //           {Math.abs(bounceRate)}%
  //         </td>
  //       </tr>
  //     );
  //   };
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata",
  };
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await Axios.get(`/api/v1/users/${id}`);

        console.log(response);

        setData(response.data.user);
        setLoading(false);
      };

      fetchData();
    } catch (e) {
      console.log(e.response.data);
    }
  }, []);

  if (loading) {
    return <h6> loading </h6>;
  }
  return (
    <div className="container mt-4">
      <Row>
        <Row>
          <Col className="d-flex justify-content-start m-2">
            <Link to="/components/user" className="btn btn-primary">
              Go to All Users
            </Link>
          </Col>
          <Col className="d-flex justify-content-end m-2">
            <Link to={`/users/${id}/invoices`} className="btn btn-success">
              User Invoices
            </Link>
          </Col>
        </Row>
        {/* User Details Section */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-primary text-white py-3">
              <h4 className="mb-0">User Profile</h4>
            </Card.Header>
            <Card.Body className="p-0">
              <ListGroup variant="flush">
                {renderField("User Name", data.name)}
                {renderField("Referral Code", data.referralCode)}

                {renderField("Plan Name", data.subscription.plan.planName)}
                {renderField("Plan Type", data.subscription.planType)}
                {renderField(
                  "Trail start date",
                  new Date(data.trailStartTime).toLocaleDateString(
                    "en-US",
                    options
                  )
                )}

                {renderField(
                  "Trail End Date",
                  new Date(data.trailExpirationTime).toLocaleDateString(
                    "en-US",
                    options
                  )
                )}
                {renderField(
                  "current subscription Start date",
                  new Date(data.subscription.startDate).toLocaleDateString(
                    "en-US",
                    options
                  )
                )}
                {renderField(
                  "current subscription end date",
                  new Date(data.subscription.expirationDate).toLocaleDateString(
                    "en-US",
                    options
                  )
                )}
                {renderField(
                  "Joining Date",
                  new Date(data.createdAt).toLocaleDateString("en-US", options)
                )}
                {renderField("Status", data.isActive ? "True" : "False")}
                {renderField(
                  "Keywords",
                  <div className="d-flex flex-wrap gap-1">
                    {data.keywords.map((keyword, index) => (
                      <span
                        key={index}
                        className="bg-secondary text-white px-2 py-1 rounded-pill small"
                      >
                        {keyword}
                      </span>
                    ))}
                  </div>
                )}
                {renderField("Mobile Number", data.mobileNumber)}
              </ListGroup>

              <>
                {/* <div className="border-top pt-3 pb-2 px-3">
                    <h5 className="text-muted">Additional Details</h5>
                  </div> */}
                <ListGroup variant="flush">
                  {renderField(
                    "IP Address",
                    data.ipAddress ? data.ipAddress : ""
                  )}
                  {renderField("OS", data.os ? data.os : "")}
                  {renderField(
                    "Device Name",
                    data.deviceName ? data.deviceName : ""
                  )}
                  {renderField(
                    "Resolution",
                    data.deviceResolution ? data.deviceResolution : ""
                  )}

                  {renderField("Device ID", data.deviceId ? data.deviceId : "")}
                </ListGroup>
              </>
            </Card.Body>
          </Card>
        </Col>

        {/* Referrals Section */}
        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Header className="bg-success text-white py-3">
              <h4 className="mb-0">Referrals ({referralData.length})</h4>
            </Card.Header>
            <Card.Body className="p-0">
              <ListGroup variant="flush">
                {referralData.map((referral, index) => (
                  <ListGroup.Item key={index}>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <strong>{referral.name}</strong>
                      </Col>
                      <Col xs={6} className="text-end text-muted">
                        {referral.date}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewUser;
