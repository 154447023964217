import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Table,
  Button,
  Form,
  Container,
  Row,
  Col,
  Pagination,
  Spinner,
  Alert,
} from "@themesberg/react-bootstrap";
import Axios from "../../axios";
import { useHistory } from "react-router-dom";

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",

  timeZone: "Asia/Kolkata",
};

const SupportTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await Axios.get("/api/v1/support/getAll");
        setTickets(response.data.support);
      } catch (err) {
        setError("Failed to fetch tickets. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, []);

  const handleStatusToggle = async (id, currentStatus) => {
    setIsUpdating(id);
    setUpdateError(null);
    try {
      const newStatus = currentStatus === "Open" ? "Close" : "Open";
      await Axios.patch(`/api/v1/support/${id}/updateStatus`, {
        status: newStatus,
      });
      setTickets((prevTickets) =>
        prevTickets.map((ticket) => {
          if (ticket._id === id) {
            return { ...ticket, status: newStatus };
          }
          return ticket;
        })
      );
    } catch (err) {
      setUpdateError(`Failed to update status for ticket ID: ${id}`);
    } finally {
      setIsUpdating(null);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  const filteredTickets = tickets.filter((ticket) => {
    if (activeTab !== "all" && ticket.status.toLowerCase() !== activeTab)
      return false;
    if (
      searchQuery &&
      !ticket.subject.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !ticket.addedBy?.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
      return false;
    return true;
  });

  // Ensure pagination is applied to the filtered results
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  // Recalculate total pages based on filtered results
  const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleReply = (id) => {
    history.push(`/customer/support/${id}`);
  };

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h1>Support Tickets</h1>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Search by Subject"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Col>
      </Row>
      {loading ? (
        <Row className="justify-content-center my-5">
          <Spinner animation="border" variant="primary" />
        </Row>
      ) : error ? (
        <Row className="justify-content-center my-5">
          <Alert variant="danger">{error}</Alert>
        </Row>
      ) : (
        <>
          <Tabs
            id="ticket-tabs"
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
            className="mb-3"
          >
            <Tab eventKey="all" title="All" />
            <Tab eventKey="open" title="Open" />
            <Tab eventKey="close" title="Close" />
          </Tabs>
          <TicketTable
            tickets={currentTickets}
            handleReply={handleReply}
            handleStatusToggle={handleStatusToggle}
            isUpdating={isUpdating}
          />
          <PaginationComponent
            totalPages={totalPages}
            currentPage={currentPage}
            paginate={paginate}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        </>
      )}
      {updateError && (
        <Row className="justify-content-center my-3">
          <Alert variant="danger">{updateError}</Alert>
        </Row>
      )}
    </Container>
  );
};

const TicketTable = ({
  tickets,
  handleReply,
  handleStatusToggle,
  isUpdating,
}) => (
  <Table striped bordered hover responsive>
    <thead>
      <tr>
        <th>S.No</th>
        <th>Subject</th>
        <th>Created On</th>
        <th>Added By</th>

        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {tickets.map((ticket, index) => (
        <tr key={ticket._id}>
          <td>{index + 1}</td>
          <td>{ticket.subject}</td>
          <td>
            {new Date(ticket.createdAt).toLocaleDateString("en-US", options)}
          </td>
          <td>{ticket.addedBy?.name}</td>
          <td>{ticket.status}</td>
          <td>
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleReply(ticket._id)}
              className="me-2"
            >
              View
            </Button>
            <Button
              variant={ticket.status === "Open" ? "danger" : "success"}
              size="sm"
              onClick={() => handleStatusToggle(ticket._id, ticket.status)}
              disabled={isUpdating === ticket._id}
              style={
                isUpdating === ticket._id
                  ? { opacity: 0.5, pointerEvents: "none" }
                  : {}
              }
            >
              {isUpdating === ticket._id ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : ticket.status === "Open" ? (
                "Close"
              ) : (
                "Open"
              )}
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const PaginationComponent = ({
  totalPages,
  currentPage,
  paginate,
  handlePrevious,
  handleNext,
}) => (
  <Pagination className="justify-content-center mt-3">
    <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 1} />
    {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => paginate(number)}
      >
        {number}
      </Pagination.Item>
    ))}
    <Pagination.Next
      onClick={handleNext}
      disabled={currentPage === totalPages}
    />
  </Pagination>
);

export default SupportTickets;
