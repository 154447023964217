import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Alert,
  Spinner, // Import Spinner component for loading indicator
} from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "./../../axios";

const SupportDetail = () => {
  const [support, setSupport] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [error, setError] = useState(""); // State for error messages
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const { ticketID } = useParams();

  const fetchSupport = async () => {
    setIsLoading(true); // Set loading to true when starting to fetch data
    try {
      const response = await Axios.get(`/api/v1/support/${ticketID}`);
      setSupport(response.data.support);
      setError(""); // Clear error if successful
    } catch (error) {
      console.error("Error fetching support ticket:", error);
      setError(error.response?.data?.msg || "Failed to fetch support ticket");
    } finally {
      setIsLoading(false); // Set loading to false after fetching is done
    }
  };

  useEffect(() => {
    fetchSupport();
  }, [ticketID]);

  const handleStatusChange = async () => {
    setIsLoading(true); // Set loading to true when updating the status
    try {
      const response = await Axios.patch(
        `/api/v1/support/${ticketID}/updateStatus`,
        {
          status: support.status === "Open" ? "Close" : "Open",
        }
      );
      setSupport(response.data.updatedSupport);
      setError(""); // Clear error if successful
    } catch (error) {
      console.error("Error updating status:", error);
      setError(error.response?.data?.msg || "Failed to update status");
    } finally {
      setIsLoading(false); // Set loading to false after the update
    }
  };

  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when sending a message
    try {
      const response = await Axios.post(
        `/api/v1/support/${ticketID}/addMessage`,
        {
          message: newMessage,
        }
      );
      setSupport(response.data.support);
      setNewMessage("");
      setError(""); // Clear error if successful
    } catch (error) {
      console.error("Error sending message:", error);
      setError(error.response?.data?.msg || "Failed to send message");
    } finally {
      setIsLoading(false); // Set loading to false after sending the message
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-4 text-center">
        <Spinner animation="border" variant="primary" />
        <p>Loading...</p>
      </div>
    );
  }

  if (!support)
    return <div className="container mt-4">No support data available.</div>;

  return (
    <div className="container mt-4">
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <h5>Ticket ID: {support._id}</h5>
          <Button
            variant={support.status === "Open" ? "success" : "secondary"}
            onClick={handleStatusChange}
          >
            {support.status === "Open" ? "Close Ticket" : "Reopen Ticket"}
          </Button>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}{" "}
          {/* Show error messages */}
          <h6>Subject: {support.subject}</h6>
          <p>Description: {support.description}</p>
          <p>
            Status: <strong>{support.status}</strong>
          </p>
          <p>
            Added By: <strong>{support.addedBy?.name}</strong>
          </p>
          <p>
            Last Reply from Admin:{" "}
            <strong>
              {support.lastReply
                ? new Date(support.lastReply).toLocaleDateString()
                : "Not Replied Yet"}
            </strong>
          </p>
          <hr />
          <div className="d-flex flex-wrap gap-3 mb-3">
            {support.images?.map((image, index) => (
              <Card
                key={index}
                style={{ width: "150px" }}
                className="text-center"
              >
                <Card.Img
                  variant="top"
                  src={image.url}
                  alt={`Image ${index + 1}`}
                  style={{ height: "100px", objectFit: "cover" }}
                />
              </Card>
            ))}
          </div>
          <h6>Messages:</h6>
          <div className="mb-3">
            {support.messages.map((msg, index) => (
              <Card key={index} className="mb-2">
                <Card.Body>
                  <p className="mb-1">
                    <strong>{msg.user}:</strong>
                  </p>
                  <p>{msg.message}</p>
                  <small className="text-muted">
                    Sent at: {new Date(msg.createdAt).toLocaleString()}
                  </small>
                </Card.Body>
              </Card>
            ))}
          </div>
          <Form onSubmit={handleSubmitMessage}>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter your message"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <Button type="submit" variant="primary">
                Send
              </Button>
            </InputGroup>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SupportDetail;
