import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
} from "@themesberg/react-bootstrap";

import Swal from "sweetalert2";
import Axios from "../../axios";
import { useHistory } from "react-router-dom";

const AddPlan = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    question: "",

    answer: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // const jwt = localStorage.getItem("jwt");
  // console.log("jwt", jwt);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await Axios.post(
        "/api/v1/faqs",
        {
          ...formData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        Swal.fire({
          title: "Success!",
          text: "Plan created successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        history.push("/faq");
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error creating the plan.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Container>
      <Card style={{ padding: "1rem", margin: "1rem" }}>
        <Row className="justify-content-md-center">
          <Col md="6">
            <h1 className="text-center mb-4">Create Plan</h1>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group controlId="formPlanName" className="mt-3">
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  name="question"
                  value={formData.question}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPrice" className="mt-3">
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  type="text"
                  name="answer"
                  value={formData.answer}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button variant="info" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default AddPlan;
