import React, { useEffect, useState } from "react";
import { Card, Spinner, Alert, Table } from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "../../axios";

const InvoiceDetails = () => {
  const { id } = useParams(); // Get invoice ID from the URL parameters
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await Axios.get(`/api/v1/invoices/${id}`); // Replace with your API endpoint

        setInvoice(response.data.invoice);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",

    timeZone: "Asia/Kolkata",
  };

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" />
        <p>Loading invoice details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center my-5">
        <Alert variant="danger">Error: {error}</Alert>
      </div>
    );
  }

  if (!invoice) {
    return (
      <div className="text-center my-5">
        <Alert variant="warning">No invoice data found.</Alert>
      </div>
    );
  }

  const {
    user,
    order,
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature,
    ipAddress,
    paymentMethod,
    details,
    createdAt,
  } = invoice;

  return (
    <div className="container mt-5">
      <Card>
        <Card.Header>
          <h4>Invoice Details of id:{id}</h4>
        </Card.Header>
        <Card.Body>
          <Table bordered>
            <tbody>
              <tr>
                <td>
                  <strong>Username</strong>
                </td>
                <td>{order.userId.name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Plan Name</strong>
                </td>
                <td>{order.plan.planName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Plan Type</strong>
                </td>
                <td>{order.planType}</td>
              </tr>
              <tr>
                <td>
                  <strong>Start Date</strong>
                </td>
                <td>
                  {new Date(order.startDate).toLocaleDateString(
                    "en-US",
                    options
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Expiry Date</strong>
                </td>
                <td>
                  {new Date(order.expiryDate).toLocaleDateString(
                    "en-US",
                    options
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Razorpay Payment ID</strong>
                </td>
                <td>{razorpay_payment_id}</td>
              </tr>
              <tr>
                <td>
                  <strong>Razorpay Order ID</strong>
                </td>
                <td>{razorpay_order_id}</td>
              </tr>
              <tr>
                <td>
                  <strong>Razorpay Signature</strong>
                </td>
                <td>{razorpay_signature}</td>
              </tr>
              <tr>
                <td>
                  <strong>IP Address</strong>
                </td>
                <td>
                  {ipAddress ? ipAddress : "created before code updation"}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Payment Method</strong>
                </td>
                <td>{paymentMethod}</td>
              </tr>

              {details &&
                Object.entries(details).map(([key, value]) => (
                  <tr key={key}>
                    <td>
                      <strong>{key}</strong>
                    </td>
                    <td>{value}</td>
                  </tr>
                ))}
              <tr>
                <td>
                  <strong>Paid At</strong>
                </td>
                <td>
                  {createdAt
                    ? new Date(createdAt).toLocaleString()
                    : "Created before schema update"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InvoiceDetails;
