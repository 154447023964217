import React, { useState, useEffect } from "react";
import Axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faUserShield,
  faComments,
  faCommentsDollar,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "@themesberg/react-bootstrap";

import { CounterWidget } from "../../components/Widgets";

export default () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  // const [activeUserCount, setActiveUserCount] = useState(0);
  // const [suspendedUserCount, setSuspendedUserCount] = useState(0);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await Axios.get("/api/v1/users/getAllUsersCount");
      setData(response.data.data);
      // console.log(response.data);
      // setActiveUserCount(response?.data?.activeUserCount);
      // setSuspendedUserCount(response?.data?.suspendedUserCount);
    } catch (err) {
      setError(err.msg);
      //console.log("error in get user", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    // getDashboardData();
    getUsers();
  }, []);

  return (
    <>
      <Row className="justify-content-md-between">
        {loading ? (
          <div colSpan="6" className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Total Users"
                title={data?.usersCount || ""}
                icon={faChartLine}
                iconColor="shape-secondary"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Active Users"
                title={data?.activeUsersCount || ""}
                icon={faUserShield}
                iconColor="shape-secondary"
                // bgColor="rgb(134 239 172)"
              />
            </Col>
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Suspended Users"
                title={data?.suspendedUsersCount || ""}
                icon={faEye}
                iconColor="shape-secondary"
              />
            </Col>

            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Paid Users"
                title={data?.paidUsersCount || ""}
                period="Feb 1 - Apr 1"
                percentage={28.4}
                icon={faCommentsDollar}
                iconColor="shape-tertiary"
              />
            </Col>

            <Col xs={12} sm={6} xl={4} className="mb-4"></Col>
          </>
        )}
      </Row>
    </>
  );
};
