import React, { useState } from "react";
import { Form, Button, Alert } from "@themesberg/react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Axios from "../axios";
import { useHistory } from "react-router-dom";

function ContentCreator() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const history = useHistory();
  const handleSubmit = async (e) => {
    console.log("cliked");
    e.preventDefault();
    try {
      const response = await Axios.post("/api/v1/content", {
        title,
        content,
      });

      if (response.data.status !== "success") {
        throw new Error(response.data.message || "Failed to create content");
      }
      setMessage("Content created successfully");
      setTitle("");
      setContent("");
      history.push("/settings/content");
    } catch (err) {
      console.log(err.response.data);
      setError(err.message);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Create Content</h2>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Content</Form.Label>
          <ReactQuill
            value={content}
            onChange={setContent}
            theme="snow"
            required
          />
        </Form.Group>
        <Button type="submit" variant="primary">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default ContentCreator;
