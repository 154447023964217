import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
} from "@themesberg/react-bootstrap";

import Swal from "sweetalert2";
import Axios from "../../axios";
import { useHistory, useParams } from "react-router-dom";

const UpdatePlan = () => {
  const { id } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const jwt = localStorage.getItem("jwt");

  const getFaq = async () => {
    try {
      const response = await Axios.get(`/api/v1/faqs/${id}`);

      const faq = response?.data.faq;

      // Adjust the structure and types as needed
      const formattedData = {
        question: faq.question || "",
        answer: faq.answer || "",
      };

      setFormData(formattedData);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error on finding the Faq to update.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.patch(
        `/api/v1/faqs/${id}`,
        {
          ...formData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        Swal.fire({
          title: "Success!",
          text: "Faq Updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        history.push("/faq");
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error on updating  the Faq.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    getFaq();
  }, []);
  return (
    <Container>
      <Card style={{ padding: "1rem", margin: "1rem" }}>
        <Row className="justify-content-md-center">
          <Col md="6">
            <h1 className="text-center mb-4">Update Faq</h1>
            <Form onSubmit={(e) => handleUpdate(e)}>
              <Form.Group controlId="formPlanName" className="mt-3">
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  name="question"
                  value={formData.question}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPrice" className="mt-3">
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  type="text"
                  name="answer"
                  value={formData.answer}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button variant="info" type="submit" className="mt-3">
                Update
              </Button>
              <Button
                variant="danger"
                style={{ marginLeft: "1rem" }}
                className="mt-3 ml-3"
                onClick={() => {
                  history.push("/faq");
                }}
              >
                Back
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default UpdatePlan;
