// import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
// import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
//import { Link, useHistory } from 'react-router-dom';
// import { Routes } from "../../routes";
// import BgImage from "../../assets/img/illustrations/signin.svg";
// import Select from "react-select";
// import axios from "axios";
// import Swal from 'sweetalert2';
// import Axios from "../../axios";
// // import Axios from "../../axios"
// // import Cookies from "js-cookie";
// ;
// // import Axios from "../../axios";

// const hardcodedCountryCodes = [
//   { label: "India (+91)", value: "+91" },
// ];

// const SignIn = () => {
// const history = useHistory();
// const [formData, setFormData] = useState({
//   email: "",
//   password: "",
// });

//   // console.log(formData);

//   const [loading, setLoading] = useState(false);

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     // const fullMobile = formData.countryCode.value + formData.mobile;
//     // console.log(fullMobile);

//     // console.log(requestData);

//     try {

//       const response = await Axios.post("/api/admin/login", {
//         email: formData.email,
//         password: formData.password
//       });
//       // const response = await axios.post("https://leadesh-whatsapp.onrender.com/api/signin", requestData);
//       // const jwtToken = response.headers['Jwt'];
//       // console.log('Response Headers:', response.headers);

//       // console.log("response is ", response);
//       const jwt = response.data.jwt;
//       if (response.status === 200) {
//         // console.log("JWT Token is ", jwt);
//         localStorage.setItem("jwt", jwt);
//         // Cookies.set('jwt', jwt)

//         // history.push('/#download');
//         // console.log('JWT Token:', jwt);

//         Swal.fire({
//           icon: 'success',
//           title: 'Sign-In Successful',
//           text: 'Welcome back'
//         }).then((result) => {
//           if (result.isConfirmed) {

//             history.push(Routes.Presentation.path);
//           }
//         });

//         // history.push(Routes.Presentation.path);
//       }
//     } catch (error) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Sign-In Failed',
//         text: 'Invalid email or password. Please try again.',
//       });

//       console.log(error);
//     }
//     setLoading(false);

//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleCountryCodeChange = (selectedOption) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       countryCode: selectedOption
//     }));
//   };

//   const checkLogin = async () => {
//     const jwt = localStorage.getItem('jwt');

//     if (jwt) {
//       history.push(Routes.Presentation.path)
//     }
//   }
//   useEffect(() => {

//     checkLogin();
//   }, [])

//   return (
//     <main>
//       <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
//         <Container>

//           <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
//             <Col xs={12} className="d-flex align-items-center justify-content-center">
//               <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
//                 <div className="text-center text-md-center mb-4 mt-md-0">
//                   <h3 className="mb-0">Sign in to our platform</h3>
//                 </div>
//                 <Form className="mt-4" onSubmit={handleFormSubmit}>

//                   <Form.Group id="email" className="mb-4">
//                     <Form.Label>Your Email Id</Form.Label>
//                     <InputGroup>
//                       <InputGroup.Text>
//                         <FontAwesomeIcon icon={faEnvelope} />
//                       </InputGroup.Text>
//                       <Form.Control
//                         autoFocus
//                         required
//                         type="email"
//                         placeholder="example@gmail.com"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleInputChange}
//                         pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
//                         title="Please enter a valid email address"
//                       />
//                     </InputGroup>
//                   </Form.Group>

//                   <Form.Group id="password" className="mb-4">
//                     <Form.Label>Your Password</Form.Label>
//                     <InputGroup>
//                       <InputGroup.Text>
//                         <FontAwesomeIcon icon={faUnlockAlt} />
//                       </InputGroup.Text>
//                       <Form.Control
//                         required
//                         type="password"
//                         placeholder="Password"
//                         name="password"
//                         value={formData.password}
//                         onChange={handleInputChange}
//                       />
//                     </InputGroup>
//                   </Form.Group>
//                   <div className="d-flex justify-content-between align-items-center mb-4">

//                     <Card.Link as={Link} to={Routes.ResetPassword.path} className="small text-end">Lost password?</Card.Link>
//                   </div>
//                   <Button variant="primary" type="submit" className="w-100">
//                     {
//                       loading ? <>Loading...</> : <>Sign in</>
//                     }
//                   </Button>
//                 </Form>

//                </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </main>
//   );
// };

// export default SignIn

import React, { useState } from "react";
import Axios from "./../../axios";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { useEffect } from "react";

const MobileOTPLogin = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showOTPInput, setShowOTPInput] = useState(false);

  const getMe = async () => {
    //const jwt = localStorage.getItem("jwt");

    try {
      const response = await Axios.get("/api/v1/users/getMe", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);

      if (
        response.data.status === "success" &&
        response.data.user.role === "admin"
      ) {
        // console.log(respose.data);
        history.push("/");
      }
    } catch (err) {
      console.log("error in get user", err);
    }
  };

  useEffect(() => {
    // if (!jwt) {
    //   history.push(Routes.Signin.path);
    //   return;
    // }

    getMe();
  }, []);

  const history = useHistory();

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const handleSendOTP = async () => {
    try {
      setLoading(true);
      const number = "91" + mobileNumber;
      console.log(number);
      console.log(mobileNumber);
      const response = await Axios.post("/api/v1/otp/sendOTP", {
        number: number,
      });
      console.log(response);
      setShowOTPInput(true);
    } catch (err) {
      setError("Failed to send OTP. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    const number = "91" + mobileNumber;
    try {
      setLoading(true);
      const response = await Axios.post("/api/v1/otp/verifyOTP", {
        number,
        otp,
      });
      console.log(response);

      setLoading(false);
      if (response.data.status == "success" && response.data.newUser) {
        setLoading(false);
        alert("you are not an admin");
      } else if (response.data.status === "success" && !response.data.newUser) {
        setLoading(false);
        history.push(Routes.Presentation.path);
      } else {
        setLoading(false);
        alert(response.data.message);
      }

      // Redirect to dashboard or perform other login actions
    } catch (err) {
      setError("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    const number = "91" + mobileNumber;
    setLoading(true);
    try {
      const response = await Axios.post("/api/v1/otp/resendOTP", {
        number,
      });
      console.log(response);

      setLoading(false);
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title mb-4">Login with Mobile OTP</h3>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="mobileNumber">Mobile Number</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">+91</span>
                  </div>
                  <input
                    type="tel"
                    className="form-control"
                    id="mobileNumber"
                    placeholder="Enter your mobile number"
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    required
                  />
                </div>
              </div>
              {showOTPInput ? (
                <div className="form-group">
                  <label htmlFor="otp">OTP</label>
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    placeholder="Enter the OTP"
                    value={otp}
                    onChange={handleOTPChange}
                    required
                  />
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={handleSendOTP}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Send OTP"
                  )}
                </button>
              )}
              {showOTPInput && (
                <button
                  type="button"
                  className="btn btn-success btn-block mt-3"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Login with OTP"
                  )}
                </button>
              )}
              {showOTPInput && (
                <button
                  type="button"
                  className="btn btn-success btn-block mt-3"
                  onClick={resendOTP}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Resend OTP"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileOTPLogin;
