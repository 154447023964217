import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spinner, Alert } from "@themesberg/react-bootstrap";
import {
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Axios from "../../axios";

const StatsDashboard = () => {
  const [data, setData] = useState({ week: [], month: [], year: [], hour: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await Axios.get("/api/v1/admin/getRevenueStats");
        setData(response.data.data);
      } catch (err) {
        setError("Failed to fetch statistics. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const renderGraph = (type, data, xKey, yKey, xLabel, title) => (
    <Card className="mb-4">
      <Card.Header>
        <h5>{title}</h5>
      </Card.Header>
      <Card.Body>
        <ResponsiveContainer width="100%" height={400}>
          {type === "line" ? (
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xKey}
                label={{
                  value: xLabel,
                  position: "insideBottomRight",
                  offset: -5,
                }}
              />
              <YAxis
                label={{
                  value: "Revenue",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey={yKey}
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          ) : (
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xKey}
                label={{
                  value: xLabel,
                  position: "insideBottomRight",
                  offset: -5,
                }}
              />
              <YAxis
                label={{
                  value: "Revenue",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey={yKey} fill="#82ca9d" />
            </BarChart>
          )}
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="p-4">
      <Row>
        <Col>
          {renderGraph(
            "line",
            data.hour,
            "hour",
            "revenue",
            "hourRange",
            "Hourly Revenue"
          )}
          {renderGraph(
            "bar",
            data.week,
            "day",
            "revenue",
            "Days of the Week",
            "Weekly Revenue"
          )}
          {renderGraph(
            "line",
            data.month,
            "day",
            "revenue",
            "Days of the Month",
            "Monthly Revenue"
          )}
          {renderGraph(
            "bar",
            data.year,
            "month",
            "revenue",
            "Months",
            "Yearly Revenue"
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StatsDashboard;
