import React, { useState, useEffect } from "react";
import { Table, Button, Spinner } from "@themesberg/react-bootstrap";
import Axios from "../axios";
import { useHistory } from "react-router-dom";

function ItemTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    // Fetch data from the backend when the component mounts
    const fetchData = async () => {
      try {
        const response = await Axios.get("/api/v1/content");
        console.log(response);
        // Replace with your API endpoint

        setData(response.data.content);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await Axios.delete(`/api/v1/content/${id}`);

      const newData = data.filter((item) => item._id !== id);

      setData(newData);
      setLoading(false);

      console.log(response);
    } catch (e) {
      setLoading(false);
      console.log(e.response.data);
    }
  };

  return (
    // <Table striped bordered hover>
    //   <thead>
    //     <tr>
    //       <th>Sl No</th>
    //       <th>Name</th>
    //       <th>Action</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {data.map((item, index) => (
    //       <tr key={item.id || index}>
    //         <td>{index + 1}</td>
    //         <td>{item.title}</td>
    //         <td>
    //           <Button variant="primary" onClick={() => handleView(item)}>
    //             View
    //           </Button>
    //         </td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </Table>
    <section>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <h1
          style={{ flex: 1, textAlign: "center" }}
          className="text-center mb-1"
        >
          Content
        </h1>
        <Button
          variant="success"
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            textTransform: "capitalize",
            marginRight: "5px",
          }}
          onClick={() => history.push("/settings/content/createContent")}
        >
          Add Content
        </Button>
      </div>

      <table className="table table-hover table-responsive-xl table-responsive-md">
        <thead>
          <tr>
            <th scope="col">Sl No</th>
            <th scope="col">Plan Name</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        {loading ? (
          <tbody className="w-100">
            <tr>
              <td colSpan="6" className="text-center">
                <div colSpan="6" className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data ? (
              data.map((item, index) => (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <th>{item?.title}</th>
                  {/* <th>{item?.yearlyPrice}</th>

                  <th>{item?.maxkeywords}</th> */}

                  <th>
                    {" "}
                    {/* <Button>View</Button> */}
                    <button
                      className="btn btn-info"
                      // style={{ background: "black", color: "white" }}
                      onClick={() =>
                        history.push(`/settings/content/${item.title}`)
                      }
                    >
                      View
                    </button>
                    {/* <Button>delete</Button> */}
                    <button
                      className="btn ms-3 btn-danger"
                      // style={{ background: "blue", color: "white" }}
                      onClick={() => handleDelete(item?._id)}
                    >
                      Delete
                    </button>
                  </th>
                </tr>
              ))
            ) : (
              <>
                <tr>
                  <td colSpan="6" className="text-center">
                    <p>No Contents</p>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        )}
      </table>
    </section>
  );
}

// Dummy handler for the "View" button

export default ItemTable;
