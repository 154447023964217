import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "../../axios";
import { Dropdown } from "bootstrap";
import { Button } from "@themesberg/react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

const Faq = () => {
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState();

  const getfaqs = async () => {
    setLoading(true);
    //const jwt = localStorage.getItem("jwt");

    try {
      const respose = await Axios.get("/api/v1/faqs", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log(respose.data);
      setFaqs(respose?.data?.faqs);
    } catch (err) {
      console.log("error in get user", err);
    }
    setLoading(false);
  };

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  useEffect(() => {
    getfaqs();
  }, []);

  const history = useHistory();
  // const rowsPerPage = 8;
  // const [currentPage, setCurrentPage] = useState(1);
  // const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentRows = faqs?.slice(indexOfFirstRow, indexOfLastRow);

  // const totalPages = Math.ceil(faqs?.length / rowsPerPage);

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  // const handleDelete = async (planId) => {
  //   console.log(planId);
  //   const jwt = localStorage.getItem("jwt");

  //   try {
  //       // Sending delete request
  //      const response = await Axios.delete(`/api/delete-plan/${planId}`, {
  //         headers: {
  //           jwt: `${jwt}`,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       if (response.status === 200) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "Plan Deleted  Successfully",
  //           text: "Plan has been deleted successfully!",
  //           button: "OK",
  //         });
  //       }

  //       // Refresh the list of plans after deletion
  //       faqs();
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "something went wronge",

  //       button: "OK",
  //     });
  //     console.error("Error deleting the plan", error);

  //   }
  // };

  const handleDelete = async (planId) => {
    console.log(planId);

    // Show confirmation popup before deleting
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Sending delete request
          const response = await Axios.delete(`/api/v1/faqs/${planId}`, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              title: "Faq Deleted Successfully",
              text: "Faq has been deleted successfully!",
              button: "OK",
            });
          }

          // Refresh the list of plans after deletion
          getfaqs();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "There was an issue deleting the Faq.",
            button: "OK",
          });
          console.error("Error deleting the Faq", error);
        }
      } else {
        // If the user cancels the delete action, log or do nothing
        console.log("Deletion canceled");
      }
    });
  };

  return (
    <section>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <h1
          style={{ flex: 1, textAlign: "center" }}
          className="text-center mb-1"
        >
          FAQs
        </h1>
        <Button
          variant="success"
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            textTransform: "capitalize",
            marginRight: "5px",
          }}
          onClick={() => history.push("/add-faq")}
        >
          Add FAQ
        </Button>
      </div>

      <table className="table table-hover table-responsive-xl table-responsive-md">
        <thead>
          <tr>
            <th scope="col" style={{ width: "5%" }}>
              Id
            </th>
            <th
              scope="col"
              className="text-wrap"
              style={{ maxWidth: "300px", width: "40%" }}
            >
              Question
            </th>
            <th
              scope="col"
              className="text-wrap"
              style={{ maxWidth: "300px", width: "40%" }}
            >
              Answer
            </th>
            <th scope="col" style={{ width: "15%" }} className="text-center">
              Action
            </th>
          </tr>
        </thead>

        {loading ? (
          <tbody className="w-100">
            <tr>
              <td colSpan="4" className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {faqs && faqs.length > 0 ? (
              faqs.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "5%" }} className="text-truncate">
                    {item?.faqId}
                  </td>
                  <td
                    style={{
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    className="text-wrap"
                  >
                    {item?.question}
                  </td>
                  <td
                    style={{
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    className="text-wrap"
                  >
                    {item?.answer}
                  </td>
                  <td className="text-center" style={{ width: "15%" }}>
                    <button
                      className="btn btn-info"
                      onClick={() => history.push(`/update-faq/${item?.faqId}`)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn ms-3 btn-danger"
                      onClick={() => handleDelete(item?.faqId)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  <p>No FAQs</p>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </section>
  );
};

export default Faq;
