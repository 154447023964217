import React, { useState, useEffect } from "react";
import { Form, Button, Spinner, Alert } from "@themesberg/react-bootstrap";
import Axios from "../axios";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};

const formats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
];

function ContentEditor() {
  const [data, setData] = useState({ title: "", content: "" });
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const { title } = useParams();

  useEffect(() => {
    // Fetch initial data from the backend
    const fetchData = async () => {
      try {
        const response = await Axios.get(`/api/v1/content/${title}`);
        console.log(response);
        // Replace with your actual API endpoint

        setData(response.data.content);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleChange = (value) => {
    setData({ ...data, content: value });
  };
  const handleUpdate = async (id) => {
    console.log(id);
    try {
      const response = await Axios.patch(`/api/v1/content/${id}`, {
        ...data,
      });

      console.log(response.data);

      if (response.data.status !== "success") {
        throw new Error(response.data.message || "Failed to update content");
      }
      setMessage("Content updated successfully");
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <h2>Edit Content</h2>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={data.title}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Content</Form.Label>
          {/* <Form.Control
            as="textarea"
            rows={5}
            name="content"
            value={data.content}
            onChange={handleInputChange}
          /> */}

          <ReactQuill
            id="body"
            value={data.content || ""}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          />
        </Form.Group>
        <Button variant="primary" onClick={() => handleUpdate(data._id)}>
          Update Content
        </Button>
      </Form>
      {/* <div className="mt-4">
        <h3>Preview:</h3>
        <h4>{data.title}</h4>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </div> */}
    </div>
  );
}

export default ContentEditor;
